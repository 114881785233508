.hero-slider {
  overflow: hidden;
  $root: &;

  .page-main--breadcrumbs-hidden .grid > .row:first-child & {
    margin-top: -24px;
  }

  .native-app .page-main--breadcrumbs-hidden .grid > .row:first-child & {
    margin-top: -1px;
  }

  &__wrapper-outer {
    #{$root}:not(#{$root}--hero) & {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
      margin: auto;
    }
  }

  &__wrapper {
    width: 100%;
    overflow: visible;
    z-index: 1;
    margin: auto;
    padding: 10px;
    position: relative;

    .carousel__wrapper {
      overflow: visible;
    }
  }

  &__picture-wrap {
    position: relative;
    display: block;
    z-index: 0;
    width: 100%;
    height: 40vw;
    overflow: hidden;
    min-height: 300px;
    max-height: 340px;
    border-radius: $baseBorderRadius;

    @media (min-width: $screen-tablet-portrait) {
      height: 340px;
      min-height: 340px;
      max-height: 340px;
    }

    @media (min-width: $screen-tablet-landscape) {
      max-height: 380px;
    }

    @media (min-width: $screen-desktop) {
      height: 40vw;
      min-height: 400px;
      max-height: 450px;
    }

    @media (min-width: $screen-tv) {
      max-width: 2560px;
    }

    #{$root}--hero & {
      display: flex;
      justify-content: center;
      height: 40vw;
      max-height: 500px;
      min-height: 500px;
      text-align: center;

      @media (min-width: $screen-tablet-portrait) {
        min-height: 600px;
        max-height: 600px;
      }
    }
  }

  &__picture {
    margin: auto;
    z-index: 0;
  }

  &__picture-image {
    height: 100%;
    width: auto;
    position: absolute;
    z-index: 1;
    margin: auto;
    top: -6666px;
    left: -6666px;
    right: -6666px;
    bottom: -6666px;

    #{$root}--hero & {
      align-self: center;
      position: relative;
      margin: auto;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      max-height: 500px;
      min-height: 500px;

      @media (min-width: $screen-tablet-portrait) {
        min-height: 600px;
        max-height: 600px;
      }
    }
  }

  &__box-wrap {
    @include container();
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
  }

  &__box {
    padding: 0;
    width: 90%;
    text-align: left;
    position: relative;
    min-height: 140px;
    max-width: 500px;
    z-index: 10;
    transform: translateY(-50%);
    top: 50%;
    left: 8px;

    @media (min-width: $screen-tablet-portrait) {
      width: 60%;
      left: 40px;
    }

    @media (min-width: $screen-tablet-portrait) {
      left: 60px;
    }

    &--product {
      position: absolute;
      transform: none;

      // !max-width!
      @media (max-width: $screen-mobile-landscape-max) {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        max-width: none;

        #{$root}__text-container {
          background: transparentize($backgroundWhiteColor, .1);
          padding: 8px;
        }

        #{$root}__info {
          background: $backgroundWhiteColor;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 16px 16px 8px;
        }
      }

      @media (min-width: $screen-tablet-portrait) {
        background: $backgroundWhiteColor;
        bottom: 0;
        left: 0;
        top: auto;
        width: 50%;
      }
    }
  }

  &__box-html {
    padding: 0;
    width: 100%;
    text-align: left;
    position: relative;
    min-height: 140px;
    z-index: 10;
    transform: translateY(-50%);
    top: 50%;
  }

  &__box-inner {
    width: 100%;
    color: $textBaseColor;

    &--product {
      padding: 8px;

      @media (min-width: $screen-tablet-portrait) {
        padding: 20px;
      }

      #{$root}__overline,
      #{$root}__headline,
      #{$root}__subheadline {
        text-align: center;
        color: $textBaseColor;

        @media (min-width: $screen-tablet-portrait) {
          text-align: left;
        }
      }

      #{$root}__text-container {
        display: none;

        @media (min-width: $screen-tablet-portrait) {
          display: block;
        }
      }
    }

    .grid__element--bg-color5 & {
      color: $whiteColor;
    }
  }

  &__text-container {
    display: block;
    text-decoration-color: transparent;
  }

  &__text-wrap {
    @include container();
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
  }

  &__text {
    padding: 50px 0 0;
    margin: auto;
    text-align: left;
    position: relative;
    height: 100%;
    z-index: 10;

    @media (min-width: $screen-tablet-portrait) {
      position: absolute;
      width: 60%;
      height: auto;
      padding: 0;
      top: 100px;
    }

    &-inner {
      padding: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__text-headline {
    @include fontSize(40px);
    font-size: 24px;
    text-align: left;
    padding: 0;
    text-transform: none;
    line-height: 1.4;
    color: $whiteColor;
    margin: 0 0 5px;
  }

  &__text-subheadline {
    @include fontSize(16px);
    font-size: 12px;
    line-height: 1.4;
    color: $whiteColor;
    margin: 0;

    p {
      margin: 0;
      padding: 0;
    }

    a {
      color: $whiteColor;

      &:hover {
        color: $whiteColor;
        text-decoration-color: $whiteColor;
      }
    }
  }

  &__text-info {
    color: $whiteColor;

    .button {
      color: $whiteColor;

      &:hover {
        color: $whiteColor;
        text-decoration-color: $whiteColor;
      }
    }
  }

  &__container {
    #{$root}--slider & {
      max-width: $maxWidthOuter;
      overflow: hidden;
      margin: auto;
    }
  }

  .carousel__slide {
    position: relative;
    flex-shrink: 0;
    opacity: .7;
    width: 100%;

    &--active {
      margin: 0;
      opacity: 1;
      width: 100%;
    }
  }

  &__slide-content {
    display: block;
    width: 100%;
    height: auto;
    text-decoration: none;
  }

  & .carousel {
    &__page-info {
      display: none;
    }

    &__pagination {
      display: flex;
      justify-content: center;
    }

    &__button {
      display: none;

      @media (min-width: $screen-tablet-portrait) {
        top: -345px;
        display: flex;
        height: 50px;
        width: 50px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
      }

      @media (min-width: $screen-tablet-landscape) {
        top: -330px;
      }

      &--prev {
        left: 0;

        @media (min-width: $screen-desktop-xl) {
          left: 0;
        }
      }

      &--next {
        right: 0;

        @media (min-width: $screen-desktop-xl) {
          right: 0;
        }
      }
    }

    &__button-icon {
      @media (min-width: $screen-tablet-portrait) {
        background: $whiteColor !important;
        border-radius: 100%;
        width: 38px;
        height: 38px;
      }
    }

    &__pagination-wrapper {
      @include container();
      margin-top: 0;
      margin-bottom: 0;

      @media (min-width: $screen-tablet-portrait) {
        position: relative;
      }
    }

    &__pagination-button {
      display: flex;
      cursor: pointer;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center;

      .carousel__pagination-progress-bar {
        background: $carouselPaginationBackgroundColor;
        width: 12px;
        height: 12px;
        display: block;
        border-radius: 50%;
        transition: all 300ms;
      }

      &--active,
      &:active {
        .carousel__pagination-progress-bar {
          background: $carouselPaginationActiveBackground;
        }
      }

      &:hover {
        .carousel__pagination-progress-bar {
          background: $carouselPaginationHoverBackground;
        }
      }
    }
  }

  &__headline {
    @include fontSize(52px);
    position: relative;
    width: auto;
    font-weight: $fontWeightBold;
    padding: 0;
    margin: 0 0 10px;

    #{$root}__box-inner--product & {
      font-size: 20px;

      @media (min-width: $screen-mobile-large) {
        font-size: 22px;
      }

      @media (min-width: $screen-tablet-portrait) {
        font-size: 26px;
      }

      @media (min-width: $screen-laptop) {
        font-size: 36px;
      }
    }

    #{$root}__html & {
      color: $whiteColor;
      text-align: center;
      margin: 0 auto 20px;
      font-size: 26px;

      @media (min-width: $screen-tablet-portrait) {
        font-size: 32px;
      }

      @media (min-width: $screen-tablet-landscape) {
        margin: 0 auto 40px;
        font-size: 36px;
      }

      @media (min-width: $screen-laptop) {
        font-size: 48px;
      }
    }
  }

  &__subheadline {
    @include fontSize($fontSizeLarge);
    font-family: $fontSecondaryFamily;
    position: relative;
    width: auto;
    font-weight: $fontWeightRegular;

    #{$root}__box-inner--product & {
      @include fontSize(20px);
    }
  }

  &__overline {
    @include fontSize($fontSizeXXXLarge);
    font-family: $fontSecondaryFamily;
    line-height: 1.4;
    font-weight: $fontWeightRegular;
    margin: 0 0 -8px;

    #{$root}__box-inner--product & {
      @include fontSize($fontSizeLarge);
      font-size: $fontSizeSmall;
      margin: 0;
    }
  }

  &__link-button {
    width: auto;
    margin: 16px auto 0;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $blackColor;
    background: transparent;
    border-color: currentColor;
    text-transform: uppercase;

    @media (min-width: $screen-tablet-landscape) {
      margin: 24px auto 0;
    }

    #{$root}__html & {
      background: rgb(0 0 0 / .5);
      color: $whiteColor;
      margin: 30px auto 0;

      @media (min-width: $screen-tablet-landscape) {
        margin: 60px auto 0;
      }
    }

    &--hover,
    &:hover {
      background: transparent;
      box-shadow: 0 0 0 1px $buttonSecondaryTextColor inset;

      #{$root}__html & {
        background: rgb(0 0 0 / .5);
        box-shadow: 0 0 0 1px $whiteColor inset;
      }
    }

    &--product {
      padding: 0 15px;
      margin: auto 0 auto 16px;
    }

    .grid__element--bg-color5 & {
      color: $whiteColor;
    }
  }

  &__info {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
  }

  &__price {
    @include fontSize(45px);
    font-size: 30px;
    color: $textBaseColor;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    flex: 1 1;

    .price-info {
      &__current {
        width: 100%;
        margin-bottom: 8px;
      }

      &__unit {
        padding: 0 5px 0 0;
      }

      &__tax {
        padding: 0;
      }
    }
  }

  .hero-slider-product {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &__action-wrapper {
      display: flex;
    }

    &__product-button {
      width: 100%;
      padding: 8px 0;

      .button {
        width: 100%;
      }
    }

    &__button-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    &__qty-wrapper {
      margin-right: 8px;
    }

    &__specification-link {
      display: block;
      width: 100%;
      font-size: 12px;
      line-height: 1;
      text-decoration: underline;
      margin-top: 8px;
      text-align: right;
      color: $textBaseColor;
    }
  }

  // Pull hero teaser in first grid field up behind the breadcrumb
  .has-breadcrumb .grid > .row:first-child > .col-xs-12 > & {
    margin-top: -68px;

    @media (min-width: $screen-desktop) {
      margin-top: -71px;
    }
  }

  // PLACEHOLDER
  carousel { // stylelint-disable-line
    display: none;
  }
}
